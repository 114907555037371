import Translate from "@mui/icons-material/Translate";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { BoundMenu } from "../../../components/BoundMenu";
import { languages } from "../../../i18n";
import { LanguageSelectionItem } from "./LanguageSelectionItem";

export const LanguageSelection = () => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setMenuOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setMenuOpen(false);
  }, [setMenuOpen]);

  const [target, setTarget] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <Tooltip title={t("LanguageSelection.selectLanguage") as string}>
        <IconButton onClick={handleOpen} ref={setTarget}>
          <Translate />
        </IconButton>
      </Tooltip>
      {target !== null && (
        <BoundMenu open={menuOpen} onClose={handleClose} target={target}>
          {languages.map((language) => (
            <LanguageSelectionItem key={language} language={language} />
          ))}
        </BoundMenu>
      )}
    </>
  );
};
